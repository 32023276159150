<template>
  <div class="container height-full">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <button
            class="button"
            :class="{
              'is-primary': !upcoming
            }"
            @click="upcoming = false">
            Recent
          </button>
        </div>
        <div class="level-item">
          <button
            class="button"
            :class="{
              'is-primary': upcoming
            }"
            @click="upcoming = true">
            Upcoming
          </button>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item" v-if="profilUser === profilPatient">
          <router-link
            tag="button"
            :to="{ name: 'CreateAppointment' }"
            class="button is-primary">
            Create Appointment
          </router-link>
        </div>
        <div class="level-item" v-if="profilUser === profilAdmin">
          <router-link
            tag="button"
            :to="{ name: 'CreateAppointmentAdmin' }"
            class="button is-primary">
            Create Appointment
          </router-link>
        </div>
      </div>
    </div>
    <b-table
      :data="appointments"
      paginated
      :per-page="perPage"
      :current-page.sync="currentPage"
      :loading="isLoading"
      default-sort="dateToCreate"
      default-sort-direction="desc">
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"/>
          </template>
          <template v-slot="props">
            <div v-if="column.field === 'doctorName'">
              <div v-if="props.row[column.field]"> {{props.row[column.field]}} </div>
              <div v-else class="has-text-danger">Not yet assigned</div>
            </div>
            <div v-else-if="column.field === 'isPay'">
              <b-tag type="is-success" v-if="props.row[column.field]">
                <span v-show="!props.row['referencePay']">Manually </span>Paid</b-tag>
              <b-tag type="is-danger" v-else>Not Paid</b-tag>
            </div>
            <div v-else-if="column.field === 'action'">
              <b-dropdown
                aria-role="list"
                position="is-bottom-left">
                <template #trigger="{}">
                  <a class="has-text-black">
                    <b-icon icon="dots-horizontal"></b-icon>
                  </a>
                </template>
                <b-dropdown-item
                  aria-role="listitem"
                  @click="$router.push({
                    name: 'ViewAppointment',
                    params: {
                      id: props.row.appointmentId,
                    }
                  })">
                  View appointment
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  v-if="[profilAdmin].includes(profilUser)"
                  @click="$router.push({
                    name: 'EditAppointmentAdmin',
                    params: {
                      appointmentId: props.row.appointmentId,
                      data: props.row
                    }
                  })">
                  Edit appointment
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  v-if="[profilPatient].includes(profilUser) && !props.row.doctorId"
                  @click="$router.push({
                    name: 'EditAppointment',
                    params: {
                      appointmentId: props.row.appointmentId
                    }
                  })">
                  Edit appointment
                </b-dropdown-item>
                <!-- <b-dropdown-item
                  aria-role="listitem"
                  v-if="[profilDoctor, profilAdmin].includes(profilUser)"
                  @click="actionAcceptAppointment(props.row)">
                  Accept appointment
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  v-if="[profilDoctor, profilAdmin].includes(profilUser)"
                  @click="showModaleRejectAppointment(props.row)">
                  Reject appointment
                </b-dropdown-item> -->
                <b-dropdown-item
                  aria-role="listitem"
                  v-if="profilUser === profilAdmin && !props.row.doctorId"
                  @click="assignDoctor(props.row)">
                  Assign doctor
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  v-if="profilUser === profilPatient && !props.row.isPay"
                  @click="actionPay(props.row)">
                  Pay appointment
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-else>
              {{ props.row[column.field] }}
            </div>
          </template>
        </b-table-column>
      </template>
      <template #empty>
        <div class="has-text-centered">No appointment</div>
      </template>
    </b-table>

    <b-modal
      v-model="isModaleAddDoctor"
      :width="600"
      @close="closeModaleAddDoctor">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Add doctor
            </p>
          </header>
          <div class="card-content" style="min-height: 200px">
            <b-field v-if="currentAppointment">
              <b-autocomplete
                v-model="searchDoctor"
                :data="filteredDataDoctor"
                icon="magnify"
                field="name"
                clearable
                open-on-focus
                @select="option => doctorSelect = option">
                <template #empty>No results found</template>
              </b-autocomplete>
            </b-field>
          </div>
          <footer class="card-footer">
            <a
              class="card-footer-item has-text-primary"
              @click="closeModaleAddDoctor">Cancel</a>
            <a
              class="card-footer-item has-background-primary has-text-white"
              @click="actionAssignDoctor">Assign</a>
          </footer>
        </div>
    </b-modal>
    <b-modal
      v-model="isModaleRejectAppointment"
      :width="300"
      @close="closeModaleRejectAppointment">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Reject appointment
            </p>
          </header>
          <div class="card-content">
            <b-field v-if="currentAppointment">
              <b-input type="textarea" v-model="doctorDesc"></b-input>
            </b-field>
          </div>
          <footer class="card-footer">
            <a
              class="card-footer-item has-text-primary"
              @click="closeModaleRejectAppointment">Cancel</a>
            <a
              class="card-footer-item has-background-primary has-text-white"
              @click="actionRejectAppointment">Assign</a>
          </footer>
        </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import {
  GetAppointmentsUser,
  PayAppointment,
  AssignDoctor,
  AcceptAppointment,
  RejectAppointment,
} from '@/api/appointment';
import { GetUserDoctor } from '@/api/user';
import { PATIENT, ADMIN, DOCTOR } from '@/config/user';

export default {
  metaInfo() {
    return {
      title: `Appointments (${this.upcoming ? 'Upcoming' : 'Recent'})`,
    };
  },
  components: {},
  data() {
    return {
      appointments: [],
      columnsData: [
        {
          field: 'dateToCreate',
          label: 'Create At',
          sortable: true,
          width: 200,
          for: [ADMIN, PATIENT, DOCTOR],
        },
        {
          field: 'doctorName',
          label: 'Doctor',
          searchable: true,
          sortable: true,
          for: [ADMIN, PATIENT],
        },
        // {
        //   field: 'doctorDesc',
        //   label: 'Description doctor',
        //   searchable: true,
        //   sortable: true,
        //   'cell-class': 'truncate',
        //   for: [ADMIN, PATIENT],
        // },
        {
          field: 'patientName',
          label: 'Patient',
          searchable: true,
          sortable: true,
          for: [ADMIN, DOCTOR],
        },
        // {
        //   field: 'status',
        //   label: 'Type',
        //   searchable: true,
        //   sortable: true,
        //   for: [ADMIN, DOCTOR, PATIENT],
        // },
        {
          field: 'patientDesc',
          label: 'Description',
          searchable: true,
          sortable: true,
          'cell-class': 'truncate',
          width: 200,
          for: [ADMIN, DOCTOR, PATIENT],
        },
        {
          field: 'isPay',
          label: 'Payment',
          sortable: true,
          width: 150,
          for: [ADMIN, PATIENT],
        },
        {
          field: 'action',
          label: '',
          for: [ADMIN, DOCTOR, PATIENT],
        },
      ],
      perPage: 10,
      currentPage: 1,
      isLoading: false,
      checkedRows: [],
      profilPatient: PATIENT,
      profilAdmin: ADMIN,
      profilDoctor: DOCTOR,
      isModaleAddDoctor: false,
      isModaleRejectAppointment: false,
      doctorDesc: '',
      currentAppointment: null,
      doctorSelect: null,
      doctors: [],
      searchDoctor: '',
      upcoming: false,
    };
  },
  watch: {
    upcoming() {
      this.getData();
    },
  },
  computed: {
    ...mapGetters({
      profilUser: 'profil',
    }),
    columns() {
      return this.columnsData.filter(col => col.for.includes(this.profilUser));
    },
    filteredDataDoctor() {
      return this.doctors
        .filter((el) => {
          return el.name
            .toString()
            .toLowerCase()
            .indexOf(this.searchDoctor.toLowerCase()) >= 0;
        });
    },
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;
        let res = [];
        if (this.$route.params.data) {
          res = this.$route.params.data;
        } else {
          res = (await GetAppointmentsUser(this.upcoming)).data;
        }
        res.appointments.forEach(el => {
          el.dateToCreate = moment(el.createAt).format('YYYY/MM/DD hh:mm a');
        });
        this.appointments = res.appointments;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showError(error);
      }
    },
    async actionAcceptAppointment(appointment) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        await AcceptAppointment(appointment.appointmentId);
        loadingComponent.close();
        this.closeModaleRejectAppointment();
        await this.getData();
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
      }
    },
    async actionRejectAppointment() {
      if (!this.doctorDesc) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Description is requiered',
          position: 'is-bottom',
          type: 'is-danger',
        });
        return '';
      }
      const loadingComponent = this.$buefy.loading.open();
      try {
        await RejectAppointment(
          this.currentAppointment.appointmentId,
          this.doctorDesc,
        );
        loadingComponent.close();
        this.closeModaleRejectAppointment();
        await this.getData();
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
    async actionPay(appointment) {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const url = (await PayAppointment(
          appointment.appointmentId,
          appointment.referencePay,
        )).data;
        loadingComponent.close();
        window.location = url;
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
      }
    },
    async actionAssignDoctor() {
      if (!this.doctorSelect) {
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Select doctor',
          position: 'is-bottom',
          type: 'is-danger',
        });
        return '';
      }
      const loadingComponent = this.$buefy.loading.open();
      try {
        await AssignDoctor(
          this.currentAppointment.appointmentId,
          this.doctorSelect.userId,
        );
        loadingComponent.close();
        this.closeModaleAddDoctor();
        await this.getData();
        return '';
      } catch (error) {
        loadingComponent.close();
        this.showError(error);
        return '';
      }
    },
    assignDoctor(item) {
      this.currentAppointment = item;
      this.isModaleAddDoctor = true;
    },
    closeModaleAddDoctor() {
      this.isModaleAddDoctor = false;
      this.currentAppointment = null;
    },
    showModaleRejectAppointment(item) {
      this.isModaleRejectAppointment = true;
      this.currentAppointment = item;
    },
    closeModaleRejectAppointment() {
      this.isModaleRejectAppointment = false;
      this.currentAppointment = null;
    },
  },
  async mounted() {
    await this.getData();
    if (this.profilUser === ADMIN) {
      const resdOC = (await GetUserDoctor()).data;
      this.doctors = resdOC.users
        .map(el => ({
          ...el,
          name: `${el.firstName} ${el.lastName}`,
        }));
    }
  },
};
</script>

<style>

</style>
